import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  IconButton,
  Divider,
  Stack,
  Tooltip,
  BoxProps,
  CSSObject,
  styled,
  Theme,
} from '@mui/material';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';

import { ReactComponent as Collection } from 'assets/collection.svg';
import { ReactComponent as Pin } from 'assets/alfinete.svg';
import { ReactComponent as Clean } from 'assets/clean.svg';
import { ReactComponent as TagSecondary } from 'assets/tag-2.svg';
import { ReactComponent as Upload } from 'assets/upload-cloud.svg';
import { ReactComponent as Download } from 'assets/download.svg';
import { ReactComponent as Setting } from 'assets/setting.svg';
import { ReactComponent as Copy } from 'assets/copy.svg';
import { ReactComponent as Tag } from 'assets/tag.svg';
import { ReactComponent as Info } from 'assets/info.svg';
import { ReactComponent as Map } from 'assets/map.svg';

import { useLibrary, useLocales, useCollection } from 'hooks';
import { ProtectedComponent } from 'components';
import { IImageMetadata } from 'interfaces';
import InfoContent from './InfoContent';
import MapContent from './MapContent';
import CollectionContent from './CollectionContent';
import { enqueueSnackbar } from 'notistack';

const drawerWidth = 336;

const openedMixin = (theme: Theme, width: number | string): CSSObject => ({
  width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 0,
  padding: 0,
});

interface StyledBoxProps extends BoxProps {
  open?: boolean;
  mapsExpanded?: boolean;
}

const StyledBox = styled(Box)<StyledBoxProps>(
  ({ theme, open, mapsExpanded }) => ({
    overflow: 'none',
    display: 'flex',
    flex: 1,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open &&
      !mapsExpanded && {
        ...openedMixin(theme, drawerWidth),
      }),
    ...(mapsExpanded && {
      ...openedMixin(theme, '90%'),
    }),
    ...(!open && {
      ...closedMixin(theme),
    }),
  })
);

const InfoButton = memo(function InfoButton({
  children,
  disabled,
}: {
  children: React.ReactElement<any, any>;
  disabled: boolean;
}) {
  if (disabled) {
    return (
      <Tooltip title="Selecione somente uma foto" followCursor>
        <Stack direction="column">{children}</Stack>
      </Tooltip>
    );
  }

  return <>{children}</>;
});

type NavigationBarProps = {
  selectedImages?: string[];
  clearSelectedImages?: () => void;
  setShowUpload?: React.Dispatch<React.SetStateAction<boolean>>;
};

const NavigationBar = ({
  selectedImages,
  clearSelectedImages,
  setShowUpload,
}: NavigationBarProps) => {
  const { downloadFolders, downloadImages, localeInfo, getLocaleById } =
    useLocales();

  const {
    selectedFolders,
    fetchImageMetadata,
    isLoading,
    toggleDownload,
    clearSelectedFolders,
  } = useLibrary();

  const { addImages, clearImages, downloadCollection, collectionImages } =
    useCollection();

  const [mapsExpanded, setMapsExpanded] = useState(false);
  const [mapsOpen, setMapsOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [collectionOpen, setCollectionOpen] = useState(false);
  const [imageMetadata, setImageMetadata] = useState<IImageMetadata | null>(
    null
  );

  const onClickDownload = useCallback(() => {
    if (collectionImages.length > 0) {
      downloadCollection();
      return;
    }
    if (selectedFolders.length > 0) {
      downloadFolders(selectedFolders);
      clearSelectedFolders();
      return;
    }
    if (selectedImages && selectedImages.length > 0) {
      return downloadImages(selectedImages);
    }
    if (localeInfo) {
      return downloadFolders([localeInfo.id]);
    }
  }, [
    selectedImages,
    selectedFolders,
    downloadFolders,
    downloadImages,
    localeInfo,
    clearSelectedFolders,
    downloadCollection,
    collectionImages,
  ]);

  const open = useMemo(
    () => mapsOpen || infoOpen || collectionOpen,
    [mapsOpen, infoOpen, collectionOpen]
  );

  const onOpen = useCallback(
    (property: string) => {
      const properties: {
        [key: string]: React.Dispatch<React.SetStateAction<boolean>>;
      } = {
        mapsOpen: setMapsOpen,
        infoOpen: setInfoOpen,
        collectionOpen: setCollectionOpen,
      };

      Object.keys(properties).forEach((key) => {
        if (key !== property) {
          properties[key](false);
        } else {
          properties[key]((p) => (p ? !p : open || !p));
        }
      });
    },
    [open]
  );

  useEffect(() => {
    if (selectedImages?.length !== 1) {
      setInfoOpen(false);
      setMapsOpen(false);
    }
  }, [selectedImages?.length]);

  useEffect(() => {
    if (infoOpen) {
      const image = localeInfo?.images?.find(
        (i) => i.id === selectedImages?.[0]
      );
      if (image) {
        fetchImageMetadata(image.url, (data) =>
          setImageMetadata({ ...data, name: image.name })
        );
      }
    }
  }, [fetchImageMetadata, infoOpen, localeInfo, selectedImages]);

  const handleAddCollection = useCallback(() => {
    if (!localeInfo || !selectedImages?.length) {
      enqueueSnackbar(
        'Você precisa ter selecionado um local e/ou selecionado imagens para adicionar a coleção',
        {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
      return;
    }
    addImages(
      localeInfo!.id,
      localeInfo!.name,
      localeInfo!.images
        .filter((i) => selectedImages?.includes(i.id))
        .map((i) => ({ id: i.id, url: i.thumbnail, name: i.name }))
    );
    clearSelectedImages?.();
  }, [localeInfo, selectedImages, clearSelectedImages, addImages]);

  return (
    <>
      <Box
        minWidth="54px"
        height="100%"
        borderLeft="1px solid"
        borderColor="grey.200"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        pt={1}
        pb={2}
      >
        <Stack direction="column">
          <IconButton
            onClick={() => setCollectionOpen((p) => !p)}
            sx={{
              aspectRatio: 1,
              borderRadius: 0,
            }}
          >
            <Collection />
          </IconButton>
          <IconButton
            onClick={() => handleAddCollection()}
            sx={{
              aspectRatio: 1,
              borderRadius: 0,
            }}
          >
            <Pin />
          </IconButton>

          <IconButton
            onClick={clearImages}
            sx={{
              aspectRatio: 1,
              borderRadius: 0,
            }}
          >
            <Clean />
          </IconButton>
          <Tooltip title="Ainda não disponível" followCursor>
            <Stack direction="column">
              <IconButton
                disabled
                onClick={() => {}}
                sx={{
                  aspectRatio: 1,
                  borderRadius: 0,
                }}
              >
                <TagSecondary />
              </IconButton>
              <Divider />
              <ProtectedComponent>
                <>
                  <IconButton
                    disabled
                    onClick={() => setShowUpload?.((p) => !p)}
                    sx={{
                      aspectRatio: 1,
                      borderRadius: 0,
                    }}
                  >
                    <Upload />
                  </IconButton>
                  <Divider />
                </>
              </ProtectedComponent>
            </Stack>
          </Tooltip>
          <IconButton
            onClick={onClickDownload}
            sx={{
              aspectRatio: 1,
              borderRadius: 0,
            }}
          >
            <Download />
          </IconButton>
          <IconButton
            onClick={toggleDownload}
            sx={{
              color: 'primary.light',
              aspectRatio: 1,
              borderRadius: 0,
            }}
          >
            <NotificationsActiveOutlinedIcon sx={{ width: 20 }} />
          </IconButton>
          <Tooltip title="Ainda não disponível" followCursor>
            <Stack direction="column">
              <ProtectedComponent>
                <IconButton
                  disabled
                  onClick={() => {}}
                  sx={{
                    aspectRatio: 1,
                    borderRadius: 0,
                  }}
                >
                  <Setting />
                </IconButton>
              </ProtectedComponent>
              <Divider />
              <IconButton
                disabled
                onClick={() => {}}
                sx={{
                  aspectRatio: 1,
                  borderRadius: 0,
                }}
              >
                <Copy />
              </IconButton>
              <Divider />
            </Stack>
          </Tooltip>
        </Stack>
        <Stack direction="column">
          <IconButton
            onClick={() => {}}
            sx={{
              aspectRatio: 1,
              borderRadius: 0,
            }}
          >
            <Tag />
          </IconButton>
          <InfoButton disabled={selectedImages?.length !== 1}>
            <IconButton
              disabled={selectedImages?.length !== 1}
              onClick={() => onOpen('infoOpen')}
              sx={{
                aspectRatio: 1,
                borderRadius: 0,
              }}
            >
              <Info />
            </IconButton>
          </InfoButton>
          <IconButton
            onClick={() => onOpen('mapsOpen')}
            sx={{
              aspectRatio: 1,
              borderRadius: 0,
            }}
          >
            <Map />
          </IconButton>
        </Stack>
      </Box>
      <StyledBox
        open={open}
        mapsExpanded={mapsExpanded}
        px={2}
        py={3}
        bgcolor="grey.200"
        position="absolute"
        right="54px"
        bottom={0}
        height="calc(100vh - 143px)"
        display="flex"
        flexDirection="column"
        zIndex={99}
      >
        {mapsOpen && (
          <MapContent
            localeInfo={localeInfo}
            mapsExpanded={mapsExpanded}
            setMapsExpanded={setMapsExpanded}
            changeLocale={(id: string) => {
              getLocaleById(id);
              setMapsOpen(false);
            }}
          />
        )}
        {infoOpen && (
          <InfoContent imageMetadata={imageMetadata} isLoading={isLoading} />
        )}
        {collectionOpen && <CollectionContent />}
      </StyledBox>
    </>
  );
};

export default NavigationBar;
