import { api } from 'api';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import {
  ERROR_DOWNLOAD,
  INIT_DOWNLOAD,
  UPDATE_PERCENTAGE,
} from 'store/download.slice';
import { ADD_IMAGES, REMOVE_IMAGES, CLEAR_IMAGES } from 'store/library.slice';
import { downloadFileWithProgress } from 'utils/download';

const useCollection = () => {
  const dispatch = useAppDispatch();
  const { collectionImages } = useAppSelector(({ library }) => library);

  const addImages = useCallback(
    (
      id: string,
      name: string,
      images: { id: string; url: string; name: string }[]
    ) => {
      dispatch(ADD_IMAGES({ id, name, images }));
    },
    [dispatch]
  );

  const removeImage = useCallback(
    (localeId: string, imageId: string) => {
      dispatch(REMOVE_IMAGES({ localeId, imageId }));
    },
    [dispatch]
  );

  const clearImages = useCallback(() => {
    dispatch(CLEAR_IMAGES());
  }, [dispatch]);

  const downloadCollection = useCallback(async () => {
    try {
      const ids = collectionImages.map((l) => l.images.map((i) => i.id)).flat();
      dispatch(INIT_DOWNLOAD('Múltiplas Pastas'));
      enqueueSnackbar('Um download foi iniciado', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      const { data } = await api.post<{
        downloadUrl: string;
        message: string;
        id: string;
        totalSize: number;
      }>('/locales/download-collection/size', {
        ids,
      });

      const { message, id, downloadUrl } = data;

      const downloadName = `${collectionImages[0].name}.zip`;

      const ws = new WebSocket(
        `wss://${process.env.REACT_APP_URL_HOST?.split('//')[1].replace(
          'api',
          'wss'
        )}?id=${id}`
      );

      if (message === 'The ZIP file is being generated.') {
        ws.onopen = () => {
          ws.send(
            JSON.stringify({ locales: collectionImages, type: 'collection' })
          );
        };
        ws.onmessage = function (event) {
          const data = JSON.parse(event.data);
          if (data.type === 'error') {
            ws.close();
            enqueueSnackbar('Erro ao baixar imagens', {
              variant: 'error',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
            dispatch(ERROR_DOWNLOAD());
          }
          if (data.type === 'finished') {
            downloadFileWithProgress(
              data.downloadUrl,
              downloadName,
              (downloaded, totalSize) => {
                dispatch(
                  UPDATE_PERCENTAGE({
                    downloaded,
                    totalSize,
                  })
                );
              }
            );

            ws.close();
          }
        };
        ws.onerror = function () {
          enqueueSnackbar('Erro ao baixar imagens', {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
          dispatch(ERROR_DOWNLOAD());
          ws.close();
        };
      } else if (message === 'The ZIP file already exists.') {
        downloadFileWithProgress(
          downloadUrl,
          downloadName,
          (downloaded, totalSize) => {
            dispatch(
              UPDATE_PERCENTAGE({
                downloaded,
                totalSize,
              })
            );
          }
        );
        ws.close();
      }
    } catch (error) {
      enqueueSnackbar('Erro ao baixar imagens', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      dispatch(ERROR_DOWNLOAD());
    }
  }, [dispatch, collectionImages]);

  return {
    addImages,
    removeImage,
    clearImages,
    downloadCollection,
    collectionImages,
  };
};

export default useCollection;
