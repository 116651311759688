import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useCollection } from 'hooks';
import { useMemo } from 'react';
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined';

const CollectionContent = () => {
  const { collectionImages, removeImage } = useCollection();

  const imgsQuantity = useMemo(() => {
    return collectionImages.reduce((acc, curr) => acc + curr.images.length, 0);
  }, [collectionImages]);

  return (
    <>
      <Typography variant="subtitle1" mb={2}>
        Coleção
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {imgsQuantity} selecionadas
      </Typography>
      {collectionImages.map((collection) => (
        <Box key={collection.id} mt={2}>
          <Typography key={collection.id}>{collection.name}</Typography>
          <Typography variant="body2" color="text.secondary">
            {collection.images.length} selecionadas
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            pt={2}
            overflow="auto"
            sx={{
              '&::-webkit-scrollbar': {
                height: 7,
              },
              '&::-webkit-scrollbar-track': {
                borderRadius: 10,
              },
              '&::-webkit-scrollbar-thumb': {
                width: 10,
                borderRadius: '8px',
                backgroundColor: 'grey.800',
              },
            }}
          >
            {collection.images.map((image) => (
              <Box
                key={image.id}
                position="relative"
                pb="2px"
                sx={{
                  div: { display: 'none' },
                  ':hover': { div: { display: 'flex' } },
                }}
              >
                <img
                  src={image.url}
                  alt={collection.name}
                  width={100}
                  height={100}
                  style={{ objectFit: 'cover' }}
                />
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  bgcolor="rgba(0,0,0,0.3)"
                  color="white"
                  width={100}
                  height={100}
                  alignItems="center"
                  justifyContent="center"
                >
                  <IconButton
                    sx={{ color: 'white' }}
                    onClick={() => removeImage(collection.id, image.id)}
                  >
                    <DeleteOutlineOutlined />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Stack>
        </Box>
      ))}
    </>
  );
};

export default CollectionContent;
