import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { ReactComponent as Folder } from 'assets/folder.svg';
import { ReactComponent as FolderOpen } from 'assets/folder-open.svg';
import { ReactComponent as Switch } from 'assets/switch-1.svg';
import { ReactComponent as MultipleFolders } from 'assets/multiple-folders.svg';
import { useLocales, useLibrary } from 'hooks';

const LocaleList = () => {
  const {
    allLocales,
    getAllLocales,
    hasSearch,
    searchTotal,
    page,
    lastPage,
    nextPage,
    localeBrowser,
  } = useLocales();

  const { selectedFolders, isSelectMode, setSelectMode, handleSelectFolder } =
    useLibrary();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getAllLocales(0);
  }, [getAllLocales]);

  const clickNext = useCallback(() => {
    const currentIndex = allLocales.findIndex(
      (loc) => loc.id === localeBrowser?.id
    );
    if (currentIndex > -1 && currentIndex < allLocales.length - 1) {
      const nextLocale = allLocales[currentIndex + 1];
      handleSelectFolder(nextLocale);
    }
  }, [allLocales, handleSelectFolder, localeBrowser]);

  const clickPrevious = useCallback(() => {
    const currentIndex = allLocales.findIndex(
      (loc) => loc.id === localeBrowser?.id
    );
    if (currentIndex > 0) {
      const nextLocale = allLocales[currentIndex - 1];
      handleSelectFolder(nextLocale);
    }
  }, [allLocales, handleSelectFolder, localeBrowser]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          clickPrevious();
          break;
        case 'ArrowDown':
          event.preventDefault();
          clickNext();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [clickNext, clickPrevious]);

  useEffect(() => {
    document.getElementsByClassName('Mui-selected')?.[0]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }, [localeBrowser]);

  return (
    <Box
      sx={{
        width: '400px',
        flexDirection: 'column',
        backgroundColor: 'common.white',
        boxShadow: 2,
      }}
    >
      <Stack
        padding={2}
        pb={0}
        direction="row"
        justifyContent={hasSearch ? 'space-between' : 'flex-end'}
        alignItems="center"
      >
        {hasSearch && (
          <Typography variant="body2" justifySelf="flex-start" pb="1px">
            Exibindo {searchTotal} resultados
          </Typography>
        )}
        <Box>
          <IconButton
            aria-controls={open ? 'order-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={() => setSelectMode((p) => !p)}
          >
            <MultipleFolders />
          </IconButton>
          <IconButton
            aria-controls={open ? 'order-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <Switch />
          </IconButton>
        </Box>
        <Menu
          aria-labelledby="order-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={handleClose}>Data de criação</MenuItem>
        </Menu>
      </Stack>
      <List
        id="scrollableDiv"
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: 'calc(100vh - 215px)',
          scrollPaddingBottom: '120px',
          scrollPaddingTop: '120px',
          '&::-webkit-scrollbar': {
            width: 10,
          },
          '&::-webkit-scrollbar-track': {
            borderRadius: 10,
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '8px',
            backgroundColor: 'grey.800',
          },
        }}
      >
        <InfiniteScroll
          dataLength={allLocales.length}
          next={nextPage}
          style={{ display: 'flex', flexDirection: 'column' }}
          hasMore={!hasSearch ? page + 1 < lastPage : false}
          loader={
            <Stack p={2} width="100%" direction="row" justifyContent="center">
              <CircularProgress />
            </Stack>
          }
          scrollableTarget="scrollableDiv"
        >
          {allLocales.map((loc, index) => {
            const auxPrev =
              allLocales[index - 1]?.created?.substring(0, 4) || 'Sem data';
            const auxCurr = loc.created?.substring(0, 4) || 'Sem data';

            let dividerAux = null;
            const labelId = `checkbox-list-label-${loc.id}`;

            if (auxPrev !== auxCurr) {
              dividerAux = (
                <Stack direction="column">
                  <Divider
                    textAlign="left"
                    variant="middle"
                    sx={{
                      color: 'text.secondary',
                      'span::before': {
                        display: 'none',
                      },
                    }}
                  >
                    <Typography variant="subtitle2" fontWeight={400}>
                      {auxCurr}
                    </Typography>
                  </Divider>
                </Stack>
              );
            }
            return (
              <div key={loc.id}>
                {dividerAux}
                <ListItem
                  dense
                  disablePadding
                  sx={{
                    '.MuiListItemIcon-root': {
                      minWidth: 40,
                      marginY: 0.5,
                    },
                  }}
                >
                  <ListItemButton
                    sx={{
                      color: 'grey.800',
                      '&.Mui-selected': {
                        backgroundColor: 'primary.main',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: 'grey.700',
                        },
                      },
                      span: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                      '&:hover': {
                        backgroundColor: 'grey.300',
                      },
                    }}
                    selected={loc.id === localeBrowser?.id}
                    onClick={() => handleSelectFolder(loc)}
                  >
                    <ListItemIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectFolder(loc);
                      }}
                    >
                      {isSelectMode || selectedFolders.includes(loc.id) ? (
                        <Checkbox
                          size="small"
                          checked={selectedFolders.includes(loc.id)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                          sx={{
                            padding: 0,
                            paddingY: '2px',
                            '&.Mui-checked': {
                              color:
                                loc.id === localeBrowser?.id
                                  ? 'white'
                                  : 'primary.main',
                            },
                          }}
                          onClick={() => handleSelectFolder(loc)}
                        />
                      ) : loc.id === localeBrowser?.id ? (
                        <FolderOpen />
                      ) : (
                        <Folder />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={loc.name}
                      title={loc.name}
                    />
                  </ListItemButton>
                </ListItem>
              </div>
            );
          })}
        </InfiniteScroll>
      </List>
    </Box>
  );
};

export default LocaleList;
