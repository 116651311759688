import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CameraAltOutlined, Public } from '@mui/icons-material';
import {
  Box,
  Typography,
  Stack,
  Chip,
  Divider,
  CircularProgress,
  IconButton,
} from '@mui/material';
import dayjs from 'dayjs';

import { PhotoSlider } from 'react-photo-view';

import {
  // FileUpload,
  LibraryCard,
  SearchLocale,
  NavigationBar,
} from 'components';
import DragSelect, { DSInputElement } from 'dragselect';
import { useDrawer, useLocales, useUpload } from 'hooks';
import { LocaleList } from 'components';

import AddLocaleDialog from './AddLocaleDialog';
import { FileWithUrl } from 'components/FileUpload/FileUpload';

import { ReactComponent as Copy } from 'assets/copy.svg';
import { enqueueSnackbar } from 'notistack';

const Library = () => {
  const ds = useRef<DragSelect | null>(null);
  const { localeInfo, loadingInfo, getLocaleById, localeBrowser } =
    useLocales();
  const { images, uploaded } = useUpload();
  const { setDrawerOpen } = useDrawer();
  const [isAddLocaleOpen, setAddLocaleOpen] = useState(false);
  const [isEditLocale, setEditLocale] = useState(false);
  const [photoViewerOpen, setPhotoViewerOpen] = useState(false);
  const [photoViewerIndex, setPhotoViewerIndex] = useState(0);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [showUpload, setShowUpload] = useState(false);
  const [inputFiles, setInputFiles] = useState<FileWithUrl[]>([]);

  const [imgPerRow, setImgPerRow] = useState(4);

  useEffect(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);

  const imgHeight = useMemo(() => {
    return `calc(800px / ${10 - imgPerRow})`;
  }, [imgPerRow]);

  // const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
  //   e.preventDefault();
  //   if (localeInfo && !isAddLocaleOpen) {
  //     setShowUpload(true);
  //   }
  // };

  useEffect(() => {
    setInputFiles([]);
    setSelectedImages([]);
    setShowUpload(false);
    if (localeInfo) {
      ds.current = new DragSelect({
        selectables: document.getElementsByClassName(
          'selectable-nodes'
        ) as unknown as DSInputElement[],
        area: document.getElementById('selectable-area') as HTMLElement,
        draggability: false,
        multiSelectToggling: true,
      });

      ds.current.subscribe('DS:start:pre', () => {
        if (window.getSelection) {
          if (window.getSelection()?.empty) {
            window.getSelection()?.empty();
          } else if (window.getSelection()?.removeAllRanges) {
            window.getSelection()?.removeAllRanges();
          }
        }
      });

      ds.current.subscribe('DS:end', ({ items }) => {
        setSelectedImages(items.map((item) => item.id));
      });
    }

    return () => {
      ds.current?.stop();
    };
  }, [localeInfo]);

  useEffect(() => {
    ds.current?.addSelectables(
      document.getElementsByClassName(
        'selectable-nodes'
      ) as unknown as DSInputElement[]
    );
  }, [uploaded]);

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    // Check if the relatedTarget is outside the current element
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      setShowUpload(false);
    }
  };

  const setImageOpen = useCallback((index: number) => {
    setPhotoViewerIndex(index);
    setPhotoViewerOpen(true);
  }, []);

  const clearSelectedImages = useCallback(() => {
    setSelectedImages([]);
    ds.current?.clearSelection();
  }, []);

  const copyToClipboard = useCallback(async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      enqueueSnackbar('Nome copiado para a área de transferência', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    } catch (err) {
      console.error('Falha ao copiar o texto: ', err);
    }
  }, []);

  const coordenates = useMemo(() => {
    if (localeInfo?.googleMapsAddress) {
      return localeInfo.googleMapsAddress;
    }

    if (localeInfo?.images?.[0]?.latitude) {
      return `${localeInfo.images[0].latitude?.toFixed(
        5
      )}, ${localeInfo.images[0].longitude?.toFixed(5)}`;
    }
    return 'Coordenadas não cadastradas';
  }, [localeInfo]);

  return (
    <Stack direction="column" width="100%" bgcolor="common.white">
      <SearchLocale imgPerRow={imgPerRow} setImgPerRow={setImgPerRow} />
      <Stack
        direction="row"
        width="100%"
        bgcolor="common.white"
        // onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <LocaleList />
        <Box
          id="selectable-area"
          sx={{
            paddingX: 4,
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 143px)',
            overflowY: 'auto',
            width: '100%',
            '&::-webkit-scrollbar': {
              width: 10,
              height: 7,
            },
            '&::-webkit-scrollbar-track': {
              borderRadius: 10,
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '8px',
              backgroundColor: 'grey.800',
            },
          }}
        >
          <Stack
            direction="column"
            padding={2}
            paddingX={0}
            flex={1}
            sx={{ userSelect: 'none' }}
          >
            {!!(localeBrowser || localeInfo) && (
              <>
                <Stack direction="column">
                  <Stack direction="row" alignItems="center">
                    <Typography variant="h6" maxWidth="720px" whiteSpace="wrap">
                      {localeBrowser?.name || localeInfo?.name || '-'}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        copyToClipboard(
                          localeBrowser?.name || localeInfo?.name || ''
                        )
                      }
                      sx={{
                        ml: 1,
                        aspectRatio: 1,
                      }}
                    >
                      <Copy />
                    </IconButton>
                  </Stack>
                  {localeInfo && localeInfo.id === localeBrowser?.id && (
                    <Typography variant="caption">
                      {localeInfo?.key || localeInfo?.code || '-'}
                    </Typography>
                  )}
                  <Typography variant="h6">
                    {localeBrowser?.created || localeInfo?.created
                      ? 'Criado em: ' +
                        dayjs(
                          localeBrowser?.created || localeInfo?.created
                        ).format('DD/MM/YYYY')
                      : 'Data de criação não cadastrada'}
                  </Typography>
                </Stack>
                <Divider sx={{ mb: 3, mt: 1 }} />
              </>
            )}
            {localeInfo && localeInfo.id === localeBrowser?.id ? (
              <>
                <Stack direction="row" alignItems="center" marginBottom={0.5}>
                  <Public
                    color="disabled"
                    sx={{ color: 'action.active', mr: 1 }}
                  />
                  <Typography
                    variant="body2"
                    color="action.active"
                    marginRight={2}
                  >
                    {coordenates}
                  </Typography>
                  <CameraAltOutlined sx={{ color: 'action.active', mr: 1 }} />
                  <Typography
                    variant="body2"
                    color="action.active"
                    marginLeft={0.5}
                  >
                    {localeInfo?.images.length} fotos
                  </Typography>

                  <Stack direction="row" marginTop={1}>
                    {localeInfo.categories.map((cat) => (
                      <Chip
                        key={cat.id}
                        label={cat.name}
                        sx={{ marginRight: 1 }}
                      />
                    ))}
                  </Stack>
                </Stack>
                {/* <FileUpload
                  show={showUpload}
                  onClose={() => setShowUpload(false)}
                  inputFiles={inputFiles}
                  setInputFiles={setInputFiles}
                /> */}
                <Stack
                  display={showUpload ? 'none' : 'flex'}
                  paddingLeft={3}
                  paddingRight={3}
                  paddingBottom={12}
                  direction="row"
                  flexWrap="wrap"
                  justifyContent="center"
                  rowGap={3}
                  columnGap={1}
                  paddingTop={2}
                  sx={{ userSelect: 'none' }}
                >
                  {localeInfo?.images.length || images.length ? (
                    <>
                      {images.map((img) => (
                        <LibraryCard
                          key={img.name}
                          id={img.name}
                          name={img.name}
                          code={'ELOCS' + localeInfo.code + img.code}
                          url={img.url}
                          maxHeight={imgHeight}
                          isLoading={img.loading}
                          isUploadMode={!img.uploaded}
                          progress={img.progress}
                        />
                      ))}
                      {localeInfo?.images.map((img, index) => (
                        <LibraryCard
                          key={img.id}
                          id={img.id}
                          code={'ELOCS' + localeInfo.code + img.code}
                          name={img.name}
                          url={img.thumbnail}
                          maxHeight={imgHeight}
                          onClick={() => setImageOpen(index)}
                        />
                      ))}
                    </>
                  ) : (
                    <Box
                      display="flex"
                      width="100%"
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography variant="body1" color="textSecondary">
                        Nenhuma imagem encontrada
                      </Typography>
                    </Box>
                  )}
                </Stack>
              </>
            ) : (
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                flex={1}
              >
                {loadingInfo ? (
                  <CircularProgress />
                ) : (
                  <Typography variant="body1">
                    Selecione um local para visualizar detalhes e fazer
                    modificações
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>

          <AddLocaleDialog
            isOpen={isAddLocaleOpen}
            isEdit={isEditLocale}
            onClose={() => {
              setAddLocaleOpen(false);
              setEditLocale(false);
            }}
            onFinish={(loc) => getLocaleById(loc.id)}
          />
        </Box>
        <NavigationBar
          selectedImages={selectedImages}
          clearSelectedImages={clearSelectedImages}
          setShowUpload={setShowUpload}
        />
        <PhotoSlider
          images={
            localeInfo?.images.map((item) => ({
              src: `${item.url.replace('.jpg', '-1200.jpg')}`,
              key: item.id,
            })) || []
          }
          loadingElement={<CircularProgress />}
          visible={photoViewerOpen}
          onClose={() => setPhotoViewerOpen(false)}
          index={photoViewerIndex}
          onIndexChange={setPhotoViewerIndex}
        />
      </Stack>
    </Stack>
  );
};

export default Library;
