import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ICollectionLocale } from 'interfaces';

interface LibraryState {
  selectedFolders: string[];
  collectionImages: ICollectionLocale[];
}

const initialState: LibraryState = {
  selectedFolders: [],
  collectionImages: [],
};

export const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    SET_SELECTED_FOLDERS: (state, action: PayloadAction<string[]>) => {
      state.selectedFolders = action.payload;
    },
    ADD_FOLDERS: (state, action: PayloadAction<string[]>) => {
      state.selectedFolders = state.selectedFolders.concat(action.payload);
    },
    REMOVE_FOLDERS: (state, action: PayloadAction<string[]>) => {
      state.selectedFolders = state.selectedFolders.filter(
        (f) => !action.payload.includes(f)
      );
    },
    ADD_IMAGES: (state, { payload }: PayloadAction<ICollectionLocale>) => {
      if (state.collectionImages.some((c) => c.id === payload.id)) {
        state.collectionImages = state.collectionImages.map((c) => {
          if (c.id === payload.id) {
            const mergedList = [...c.images, ...payload.images].reduce(
              (acc, obj) => {
                acc.set(obj.id, obj);
                return acc;
              },
              new Map()
            );
            return {
              ...c,
              images: Array.from(mergedList.values()),
            };
          } else {
            return c;
          }
        });
      } else {
        state.collectionImages = state.collectionImages.concat(payload);
      }
    },
    REMOVE_IMAGES: (
      state,
      { payload }: PayloadAction<{ localeId: string; imageId: string }>
    ) => {
      state.collectionImages = state.collectionImages.map((c) => {
        if (c.id === payload.localeId) {
          return {
            ...c,
            images: c.images.filter((i) => i.id !== payload.imageId),
          };
        } else {
          return c;
        }
      });
    },
    CLEAR_IMAGES: (state) => {
      state.collectionImages = [];
    },
  },
});

export const {
  ADD_FOLDERS,
  REMOVE_FOLDERS,
  SET_SELECTED_FOLDERS,
  ADD_IMAGES,
  REMOVE_IMAGES,
  CLEAR_IMAGES,
} = librarySlice.actions;

export default librarySlice.reducer;
